import {
   getData,
   getDropdownData,
   getManyData,
   getManyFilteredData,
   getOptions, getRawList, patchData,
   postData,
   putData
} from '../../endpointService';

import {
   addressDdUrlV1,
   addressUrlV1, rawAddressUrlV1
} from '../../../config/Endpoints';

export const getAddressList = async () => {
   try {
      const res = await getManyData(addressUrlV1)
      return res[0]
   } catch (err) {
      console.log(err)
      return {}
   }
}

export const getRawAddressList = async () => {
   try {
      const res = await getRawList(rawAddressUrlV1)
      // console.log(res)
      return res.data
   } catch (e) {
      console.log(e)
      return []
   }
}

export const getAddressOptions = async () => {
   try {
      const res = await getOptions(addressUrlV1)
      return res.data.actions.POST
   } catch (err) {
      console.log(err)
      return {}
   }
}

export const getAddressDropdown = async () => {
   try {
      return await getDropdownData(addressDdUrlV1)
   } catch (err) {
      console.log(err)
   }
}

export const getFilteredAddresses = async (filters) => {
   try {
      const res = await getManyFilteredData(addressUrlV1, filters)
      return res[0]
   } catch (err) {
      console.log(err)
   }
}

export const getAddressDetail = async (id) => {
   try {
      const res = await getData(addressUrlV1, id)
      return res.data
   } catch (err) {
      console.log(err)
   }
}

export const createAddress = async (data) => {
   try {
      return await postData(addressUrlV1, data)
   } catch (err) {
      console.log(err)
      return {}
   }
}

export const updateAddress = async (id, data) => {
   try {
      // console.log(options)
      // for (const [field, value] of Object.entries(options)) {
      //    if (value.type === 'nested object' && typeof data[field] === "object") {
      //       // console.log(field)
      //       // console.log(value)
      //       data[field] = data[field]?.id
      //    }
      //    if (value.type === 'choice') {
      //       console.log(data[`${field}_display`])
      //    }
      // }
      return await patchData(addressUrlV1, data.id, data)
   } catch (err) {
      console.log(err)
   }
}

export const patchAddress = async (id, data) => {
   try {
      return await patchData(addressUrlV1, id, data)
   } catch (err) {
      console.log(err)
      return {}
   }

}