import http from "./httpService";
import {floatFormatterForFamilyLead} from '../components/Custom/floatFormatter.js'
import {fieldTypeContact} from "../config/contact/fieldTypeContact.js"
// Import Endpoints
import {familyLeadUrl, familyLeadStageUrl, familyLeadUrlv1} from '../config/Endpoints';

// const oldEndpoint = familyLeadUrlv1
const apiEndpoint= familyLeadUrlv1

const getFamilyLeads=async()=>{
    try {
        const {data} = await http.get(apiEndpoint)
        console.log(data)
        const parsedList = floatFormatterForFamilyLead(data.results, fieldTypeContact)
        return  {...data,results:parsedList}
    } catch (error) {
        console.log(error)
        return []
    }

}

const getFamilyLead=async(id,spd)=>{
    if(!id) return {}
    try {
        const {data}=await http.get(`${apiEndpoint}${id}`)
        if(spd)return data
        const parsedList = floatFormatterForFamilyLead(data, fieldTypeContact)
        return parsedList
    } catch (error) {
        console.log(error)
        return {}
    }

}


const updateFamilyLead=async(id,familyLead)=>{
    console.log({familyLead})
    const obj = {
        ...familyLead,
        ...(familyLead?.trust_name && {trust_id:familyLead?.trust_name}),

    }
    try {
        const {data} = await http.patch(`${apiEndpoint}${id}/`,obj)
        const parsedList = floatFormatterForFamilyLead(data, fieldTypeContact)
        return parsedList
    } catch (error) {
        console.log(error)
        return
    }
}


const getOptions = async() => {
    try {
        const {data}=await  http.options(`${apiEndpoint}`)
        console.log(data,'optiond')
        return data.actions.POST.contact.children
    } catch (error) {
        console.log(error)
    }
}


const getStages=async(source)=>{
    try {
        const {data}=await http.get(familyLeadStageUrl,{ cancelToken: source.token })
        return data
    } catch (error) {
        console.log(error)
        return []
    }

}


export default {
    getFamilyLeads,
    getFamilyLead,
    getOptions,
    getStages,
    updateFamilyLead
}